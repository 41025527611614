//** Import Modules */
import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Modal,
  Button,
  message,
  Table,
  Empty,
  Space,
  InputNumber,
  Checkbox,
} from "antd";

//** Import Components */
import { useDashboardFormData } from "../../../../hooks/useDashboardFormData";
import { useParams } from "react-router";
import LoadingForm from "../../../../common/LoadingForm";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import ListBackButton from "../../../../common/ListBackButton";

export default function ProductsAddonsSettings(props) {
  // Used to build the form
  const [form] = Form.useForm();

  const { business } = props;

  //* Get the ad Data
  const { id } = useParams();

  // Get addons data
  const [addons, setAddons] = useState();

  useEffect(() => {
    // const APIURL = `/api/restaurant/dish/${business.id}/${id}`;
    const APIURL = `/api/restaurant/extra/${id}`;

    axiosWithAuth()
      .get(APIURL)
      .then((res) => {
        console.log("ressssssssssssssssss", res);

        console.log("res.data.data", res.data.data);
        setAddons(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [id]);

  const [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ] = useDashboardFormData(addons);

  useEffect(() => {
    console.log("addons", addons);
    if (addons && addons.id !== undefined) {
      setFieldData();

      form.setFieldsValue({
        name: addons.name,
        description: addons.description,
        price: addons.price,
        is_available: addons.is_available === 1, // Asegúrate de que es un booleano para el checkbox
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addons]);

  const onFinish = async (values) => {
    const listingData = {
      name: values.name,
      price: values.price,
      description: values.description,
      is_available: values.is_available,
    };

    try {
      await axiosWithAuth()
        .put(
          `${process.env.REACT_APP_BE_API_URL}/api/restaurant/extra/${id}`,
          listingData
        )
        .then(() => {
          let config = {
            content: "Addon Updated Successfully",
            style: {
              background: "none",
            },
          };
          message.success(config);
        });
    } catch (error) {
      let config = {
        content: error.response.data.message
          ? error.response.data.message
          : "Generic error occurred",
        style: {
          background: "none",
        },
        duration: 6,
      };
      message.error(config);
      console.log(error);
    }
  };

  const isAvailable = (e) => {
    if (e.target.checked) {
      form.setFieldsValue({
        is_available: true,
      });
    } else {
      form.setFieldsValue({
        is_available: false,
      });
    }
  };

  return (
    <div>
      <ListBackButton
        backURL="/business/product_addons"
        btnTxt="Back To Addons List"
      />

      {loadingForm ? (
        <LoadingForm />
      ) : (
        <Form
          form={form}
          layout="vertical"
          name="form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="name"
            label="Addon"
            rules={[
              {
                required: true,
                message: "Please input a name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please input a description!",
              },
            ]}
          >
            <Input.TextArea
              showCount
              maxLength={2000}
              autoSize={{ minRows: 5, maxRows: 8 }}
            />
          </Form.Item>
          <Form.Item
            name="price"
            label="Price"
            rules={[
              {
                required: true,
                message: "Price is Required!",
              },
            ]}
          >
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="is_available"
            label="Is Available"
            valuePropName="checked"
          >
            <Checkbox onChange={isAvailable} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {isSubmitting ? "Saving" : "Save Settings"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
