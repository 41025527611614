//** Import Components */
import { useState, useEffect } from "react";
import axiosWithAuth from "../utils/axiosWithAuth";
import {
  Form,
  Input,
  Modal,
  Button,
  Divider,
  Table,
  Empty,
  Space,
  Select,
  InputNumber,
  Checkbox,
  Tag,
} from "antd";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { TABLE_DATE_FORMAT } from "../common/constants";
import UploadMedia from "../common/FormInputs/UploadMedia";
import TextArea from "antd/lib/input/TextArea";

import {
  validateMaxLength,
  validateEmail,
  validateNumbers,
  validateLength,
  validateAddress,
} from "../utils/validations";
import { useDashboardFormData } from "./useDashboardFormData";
import { dashboardGetCities } from "../routes/BusinessDashboard/data/dashboardGetListData";
import ContentFormSubtitle from "../common/ContentFormSubtitle";
import SelectDropdown from "../common/FormInputs/SelectDropdown";

export const useManageCreateMenuItem = ({
  itemType,
  ownerID,
  modalData,
  dispatchAction,
  deleteItemAPI,
  creatorID,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalForm] = Form.useForm();
  const [quantityDisabled, setQuantityDisabled] = useState(false);
  const [onOfferEnabled, setOnOfferEnabled] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const [addons, setAddons] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [
    fields,
    setFieldData,
    isSubmitting,
    // submitForm,
    // onFinishFailed,
    loadingForm,
  ] = useDashboardFormData();

  const [form] = Form.useForm();

  // Used to get dropdown menu data
  // const [cities, setCities] = useState([]);

  useEffect(() => {
    // dashboardGetCities(setCities);
    getCategoriesList();
    getAddonsList();
  }, [creatorID]);

  // useEffect(() => {
  //   getCategoriesList();
  // }, [creatorID]);

  //* Function to delete a list item
  const deleteListItem = (APIURL) => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      axiosWithAuth()
        .delete(APIURL)
        .then((res) => {
          dispatch(dispatchAction);
          console.log(res.data, APIURL);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getCategoriesList = async () => {
    try {
      if (creatorID) {
        const APIURL = `/api/restaurant/dish/categories/${creatorID}`;
        const res = await axiosWithAuth().get(APIURL);
        setProductCategories(res.data.data);
        console.log("res", res);
      }
    } catch (error) {
      console.log("Error getting categories: ", error);
    }
  };

  const getAddonsList = async () => {
    try {
      if (creatorID) {
        const APIURL = `/api/restaurant/extra/allExtrasFromRestaurant/${creatorID}`;
        const res = await axiosWithAuth().get(APIURL);
        setAddons(res.data.data);
        console.log("res", res);
      }
    } catch (error) {
      console.log("Error getting addons: ", error);
    }
  };

  //* functions to handle modal
  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const submitModalForm = () => {
    console.log("submitModalForm:");
    modalForm.submit();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    console.log("Form submitted with values:", values); // Verifica qué datos se envían
    submitForm(values, `/api/restaurant/dish/${creatorID}`, dispatchAction);
  };

  //* Submit the form
  const submitForm = async (
    /*formData,*/ values,
    APIendpoint,
    dispatchFunction
  ) => {
    // setIsSubmitting(true);
    // let formattedData = {
    //   meta_data: [],
    // };

    console.log("Values: ", values);

    let formattedData = {
      dish_category_id: values.dish_category_id,
      dish_name: values.dish_name,
      dish_description: values.dish_description,
      dish_price: values.dish_price,
      dish_image: values.dish_image,
      availability_update_method: values.availability_update_method,
      is_available: values.is_available,
      available_quantity: values.available_quantity,
      on_offer: values.on_offer,
      dish_offer_price: values.dish_offer_price,
    };

    axiosWithAuth()
      .post(APIendpoint, formattedData)
      .then(() => {
        const modal = Modal.success({
          title: "Settings Saved",
          content: `Settings were saved successfully`,
        });

        if (dispatchFunction && dispatchFunction !== undefined) {
          dispatch(dispatchFunction);
        }

        closeModal();
        modalForm.resetFields();

        setTimeout(() => {
          modal.destroy();
        }, 5000);

        // setIsSubmitting(false);
      })
      .catch((err) => {
        console.log(err.response);

        const modal = Modal.error({
          title: "Error Saving Business",
          content: err.message,
        });

        setTimeout(() => {
          modal.destroy();
        }, 5000);

        // setIsSubmitting(false);
      });
  };

  const isAvailable = (e) => {
    // setIsFreeTicket(e.target.checked);

    if (e.target.checked) {
      form.setFieldsValue({
        is_available: true,
      });
    } else {
      form.setFieldsValue({
        is_available: false,
      });
    }
  };

  const handleAvailabilityChange = (value) => {
    if (value === "manual") {
      form.setFieldsValue({
        available_quantity: 0,
      });
      setQuantityDisabled(true);
    } else {
      setQuantityDisabled(false);
    }
  };

  const handleOfferChange = (e) => {
    if (e.target.checked) {
      setOnOfferEnabled(true);
    } else {
      setOnOfferEnabled(false);
    }
  };

  //* Component that holds the modal
  const modalComponent = (
    <Modal
      open={openModal}
      title={modalData.title}
      onCancel={closeModal}
      width="80%"
      footer={[
        <Button key="back" onClick={closeModal}>
          Close
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isSubmitting}
          onClick={submitModalForm}
        >
          Submit
        </Button>,
      ]}
    >
      <div id="restaurants">
        <Form
          form={modalForm}
          layout="vertical"
          initialValues={fields}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          disabled={isSubmitting}
        >
          <div className="form-row">
            <div className="form-column-left">
              <Form.Item
                name="dish_name"
                rules={[
                  {
                    required: true,
                    message: "Please input product's name",
                  },
                ]}
              >
                <Input placeholder="Menu Item Name" />
              </Form.Item>

              <Form.Item
                name="dish_description"
                rules={[
                  {
                    required: true,
                    message: "Please input menu item's description",
                  },
                ]}
              >
                <TextArea placeholder="Description" />
              </Form.Item>

              <Form.Item
                label="Price"
                name="dish_price"
                rules={[
                  {
                    required: true,
                    message: "Please input menu item's price",
                  },
                ]}
              >
                <InputNumber addonBefore="$" placeholder="1.00" min={0} />
              </Form.Item>

              <Form.Item
                label="Category"
                name="dish_category_id"
                rules={[
                  {
                    required: true,
                    message: "Please select product's category",
                  },
                ]}
              >
                <SelectDropdown
                  showSearch={true}
                  options={
                    productCategories && productCategories.length
                      ? productCategories.map((data) => {
                          console.log("data", data);
                          return {
                            key: data.id,
                            value: data.category,
                          };
                        })
                      : []
                  }
                />
              </Form.Item>

              <Form.Item
                label="Addons"
                name="meta_addons"
                tooltip="Choose which addons you wish to include in this dish"
              >
                <SelectDropdown
                  mode="multiple"
                  showSearch={true}
                  options={
                    addons && addons.length
                      ? addons.map((data) => {
                          return {
                            key: data.name,
                            value: data.name,
                          };
                        })
                      : []
                  }
                />
              </Form.Item>

              <Form.Item
                label="Availability Method"
                name="availability_update_method"
                rules={[
                  {
                    required: true,
                    message: "Please select availability",
                  },
                ]}
              >
                <Select
                  style={{ width: 150 }}
                  placeholder="Select"
                  onChange={handleAvailabilityChange}
                  options={[
                    { value: "inventory", label: "inventory" },
                    { value: "manual", label: "manual" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="is_available"
                label="Is Available"
                valuePropName="checked"
              >
                <Checkbox onChange={isAvailable} />
              </Form.Item>
              <Form.Item
                label="Available quantity"
                name="available_quantity"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input product's quantity",
                //   },
                // ]}
              >
                <InputNumber
                  placeholder="1"
                  min={0}
                  disabled={quantityDisabled}
                />
              </Form.Item>
              <Form.Item
                name="on_offer"
                label="On offer"
                valuePropName="checked"
              >
                <Checkbox onChange={handleOfferChange} />
              </Form.Item>
              <Form.Item
                label="Offer Price"
                name="dish_offer_price"
                rules={[
                  ({ getFieldValue }) => ({
                    required: true,
                    validator(_, value) {
                      if (
                        getFieldValue("on_offer") &&
                        (value === undefined || value === null)
                      ) {
                        return Promise.reject(
                          new Error("Please input the offer price")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber
                  addonBefore="$"
                  placeholder="1.00"
                  min={0}
                  disabled={!onOfferEnabled}
                />
              </Form.Item>
            </div>

            <div className="form-column-right">
              <Form.Item
                name="dish_image"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input menu item's image",
                //   },
                // ]}
              >
                <UploadMedia
                  setMedia={(file) =>
                    form.setFieldsValue({
                      dish_image: file,
                    })
                  }
                  maxCount={1}
                  label="Product Image"
                  fieldName="dish_image"
                  form={form}
                  description="400px * 400px Image - Max Size 10MB"
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );

  const CreateItemButton = ({ btnText }) => {
    return (
      <div className="create-item">
        <Button type="primary" onClick={showModal}>
          {btnText}
        </Button>
      </div>
    );
  };

  const dateFormat = TABLE_DATE_FORMAT;

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (text) => (
        <Link to={`${text && text.category_id}`}>{text && text.dish_name}</Link>
      ),
    },
    {
      title: "Category",
      key: "category",
      render: (text) => <>{text && text.category}</>,
    },
    {
      title: "Price",
      key: "price",
      render: (text) => <>{text && text.dish_price}</>,
    },
    {
      title: "Date Created",
      key: "created",
      render: (text) => (
        <>{moment(new Date(text && text.created_at)).format(dateFormat)}</>
      ),
    },
    {
      title: "Availability",
      key: "created",
      render: (text) => (
        <>
          {text.is_available === 1 ? (
            <Tag color="success">Active</Tag>
          ) : (
            <Tag color="error">Inactive</Tag>
          )}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text) => (
        <Space size="middle">
          <button
            onClick={() => {
              navigate(`${text.dish_id}`);
            }}
          >
            Manage
          </button>
          <button
            onClick={() => {
              const APIURL = `${deleteItemAPI}/${text.dish_id}`;
              deleteListItem(APIURL, text.id);
            }}
          >
            Delete
          </button>
          {/* <button
            onClick={() => {
              navigate(`${text.id}/insights`);
            }}
          >
            Analytics
          </button> */}
        </Space>
      ),
    },
  ];

  const ListComponent = ({ emptyMessage, listItems }) => {
    console.log("listItems", listItems);
    return (
      <>
        {listItems.length > 0 ? (
          <>
            <Table columns={columns} dataSource={listItems} />
          </>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>{emptyMessage}</span>}
          />
        )}
      </>
    );
  };

  return [modalComponent, CreateItemButton, ListComponent];
};
